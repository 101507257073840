import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { useLocation } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import { GatsbyImage } from "gatsby-plugin-image";
import clsx from 'clsx';
import { Grid } from "@material-ui/core";
import * as styles from './portfolio.module.css';
import GlobalLayout from '../../layouts/globalLayout';
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

const contentfulQuery = graphql`{
    pictureHeader: file(relativePath: {eq: "services/portfolioHeaderPic.JPG"}) {
        childImageSharp {
            gatsbyImageData
        }
    },
    allContentfulPortfolioPost {
        edges {
            node {
                slug
                title
                shortDescription
                thumbnailImage {
                    gatsbyImageData(height: 400, cropFocus: CENTER)
                    title
                }
                contentful_id
                category {
                    title
                    slug
                }
            }
        }
    }
}`;

const useStyles = makeStyles({
    root: {
      textDecoration: 'none',
      boxShadow: 'none',
      backgroundColor: '#f4e9d7',
      padding: '15px'
    },
    text: {
      display: "flex",
      width: "100%",
      height: "15%",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      color: "#99261d",
      fontSize: 25,
      fontFamily: "'Tangerine', sans-serif",
    },
    media: {
      width: "100%",
      height: '400px',
      display: "flex",
    },
    actionArea: {
      
    },
  });

export default function Portfolio() {
    const portfolioData = useStaticQuery(contentfulQuery);
    const url = useLocation();
    const classes = useStyles();

    const listOfCategories = portfolioData.allContentfulPortfolioPost.edges.reduce((accumCategories, currentPortfolio) => {
        if (currentPortfolio.node && currentPortfolio.node.category && currentPortfolio.node.category.length) {
            currentPortfolio.node.category.forEach((category) => {
                if (!accumCategories.find(val => val.slug === category.slug)) {
                    accumCategories.push(category);
                }
            });
        }

        return accumCategories;
    }, []);

    return (
        <GlobalLayout bodyBackgroundColor='white'>
            <div className={styles.pictureHeaderContainer}>
                <GatsbyImage image={portfolioData.pictureHeader.childImageSharp.gatsbyImageData} className={styles.pictureHeader} />
                <div className={styles.pictureHeaderTextContainer}>
                    <div className={styles.pictureHeaderText}>
                        OUR PORTFOLIO
                    </div>
                    <div className={styles.pictureHeaderSubText}>
                        RECENT WORK AND EVENTS
                    </div>
                </div>
                {/* <GatsbyImage image={portfolioData.pictureHeader.childImageSharp.gatsbyImageData} className={styles.pictureHeader} /> */}
            </div>
            <div className={styles.portfolioPostContainer}>
                <h1 className={styles.categoryTitle}>
                    CATEGORIES
                </h1>
                <ul className={styles.categoryLinkContainer}>
                    <li className={styles.categoryLink}>
                        <a className={clsx(styles.categoryRef, {
                                [styles.categoryLinkActive]: url.hash === ''
                            })} href={`#`}>
                            All
                        </a>
                    </li>
                    {listOfCategories.length > 0 && <span>•</span>}
                    {listOfCategories && listOfCategories.map((category, index) => (
                        <>
                            <li className={styles.categoryLink}><a className={clsx(styles.categoryRef, {
                                [styles.categoryLinkActive]: url.hash.includes(category.slug)
                            })} href={`#${category.slug}`}>{category.title}</a></li>
                            {index !== listOfCategories.length - 1 && <span>•</span>}
                        </>
                    ))}
                </ul>
                <Grid container className={styles.portfoliosContainer}>
                    {
                        portfolioData.allContentfulPortfolioPost.edges.reduce((accum, portfolio) => {
                            if (url.hash === '' || (portfolio.node && portfolio.node.category && portfolio.node.category.find(cat => url.hash.includes(cat.slug)))) {
                                accum.push(
                                    <Grid item xs={12} sm={6} className={styles.portfolioSummary} key={portfolio.node.slug}>
                                        <div className={styles.portfolioSummaryContainer}>
                                        <Card 
                                            className={clsx(classes.root, styles.card)} 
                                        >
                                            <CardActionArea
                                                className={clsx(classes.actionArea)}
                                                href={`/services/portfolio/${portfolio.node.slug}`}
                                            >
                                                <CardMedia className={classes.media}>
                                                <div style={{ width: "100%" }}>
                                                    <GatsbyImage
                                                    image={portfolio.node.thumbnailImage.gatsbyImageData}
                                                    style={{
                                                        overflow: "visible",
                                                        width: "100%",
                                                        height: '100%',
                                                        margin: "auto",
                                                        position: 'relative',
                                                        zIndex: 2,
                                                        opacity: 1
                                                    }}
                                                    className={styles.topImage}
                                                    imgStyle={{ objectFit: "contain" }} />
                                                </div>
                                                </CardMedia>        
                                            </CardActionArea>
                                            <CardContent>
                                                <div className={clsx(classes.text, styles.text)}>{portfolio.node.title}</div>
                                                <div className={styles.subText}>{portfolio.node.shortDescription}</div>
                                            </CardContent>
                                            <CardActions style={{ justifyContent: "center", textDecoration: 'none' }}>
                                                <a
                                                    className={styles.actionButton}
                                                    href={`/services/portfolio/${portfolio.node.slug}`}
                                                >
                                                    VIEW FULL GALLERY
                                                </a>
                                                </CardActions>
                                            </Card>
                                            {/* <GatsbyImage className={styles.portfolioSummaryPic} image={portfolio.node.thumbnailImage.gatsbyImageData} />
                                            <div className={styles.portfolioSummaryCategory}>
                                                {`category: ${portfolio.node.category ? portfolio.node.category.map((val, index) => `${index > 0 ? ' •' : ''}${val.title}`) : 'none'}`}
                                            </div>
                                            <h4 className={styles.portfolioSummaryTitle}>{portfolio.node.title}</h4>
                                            <div className={styles.portfolioSummaryDesc}>
                                                {portfolio.node.shortDescription}
                                            </div>
                                            <Link to={`/services/portfolio/${portfolio.node.slug}`} className={styles.portfolioSummaryReadMore}>{`READ MORE . . .`}</Link> */}
                                        </div>
                                    </Grid>
                                );
                            }

                            return accum;
                        }, [])}
                </Grid>
            </div>
        </GlobalLayout>
    )
}