// extracted by mini-css-extract-plugin
export var pictureHeaderContainer = "portfolio-module--pictureHeaderContainer--1egEs";
export var pictureHeader = "portfolio-module--pictureHeader--32Vsz";
export var pictureHeaderTextContainer = "portfolio-module--pictureHeaderTextContainer--ZzVIC";
export var pictureHeaderText = "portfolio-module--pictureHeaderText--1kxyr";
export var pictureHeaderSubText = "portfolio-module--pictureHeaderSubText--2ZQIX";
export var portfolioPostContainer = "portfolio-module--portfolioPostContainer--3Tsv9";
export var categoryTitle = "portfolio-module--categoryTitle--2N1IU";
export var categoryLinkContainer = "portfolio-module--categoryLinkContainer--15_9f";
export var categoryLink = "portfolio-module--categoryLink--3-rCw";
export var categoryLinkActive = "portfolio-module--categoryLinkActive--ZWcVI";
export var categoryRef = "portfolio-module--categoryRef--24cGY";
export var portfolioSummary = "portfolio-module--portfolioSummary--AQ6kQ";
export var portfolioSummaryContainer = "portfolio-module--portfolioSummaryContainer--3ypV_";
export var portfolioSummaryPic = "portfolio-module--portfolioSummaryPic--3Qjas";
export var portfolioSummaryCategory = "portfolio-module--portfolioSummaryCategory---0JaO";
export var portfolioSummaryTitle = "portfolio-module--portfolioSummaryTitle--oxU7Y";
export var portfolioSummaryDesc = "portfolio-module--portfolioSummaryDesc--1l1sH";
export var portfolioSummaryReadMore = "portfolio-module--portfolioSummaryReadMore--33bSN";
export var card = "portfolio-module--card--1NtrP";
export var subText = "portfolio-module--subText--325v4";
export var actionButton = "portfolio-module--actionButton--iQOmC";